/** @format */

import { makeStyles } from "@mui/styles";
import { style_exports } from "@exports/style_exports";
import chatBg2 from "@assets/images/chatBg2.png";

export const Accountsettingstyles = makeStyles({
  accountstyles: {
    fontSize: "30px",
    lineHeight: "48px",
    paddingLeft: "1.5rem",
    fontWeight: "300",
    "@media screen and (max-width: 600px)": {
      paddingLeft: "0rem",
    },
  },
  root: {
    width: "60px!important",
    height: "36px!important",
  },
  switchBase: {
    transform: "translateY(-4%)",
    color: `${style_exports.skyblue_color}!important`,
    "&$checked": {
      color: `${style_exports.skyblue_color}!important`,
    },
  },
  track: {
    backgroundColor: `${style_exports.skyblue_color}!important`, // Change the color of the track
  },
  headingcolor: {
    color: "#000000",
    fontWeight: "400 !important",
    paddingLeft: "1.5rem",
  },
  headings: {
    fontWeight: "700",
    color: style_exports.shade_color,
    fontSize: "16px",
  },
});
export const complete_profile_Styles = makeStyles({
  lable: {
    fontSize: "18px",
    fontWeight: 700,
    "@media screen and (max-width: 1433px) and (min-width: 1271px)": {
      fontSize: "16px",
    },
    "@media screen and (max-width: 1270px) and (min-width: 600px)": {
      //630px
      fontSize: "14px",
    },
    "@media screen and (max-width: 400px)": {
      fontSize: "14px",
    },
  },
  subhead: {
    fontSize: "30px",
    fontWeight: 700,
  },
  browsebtn: {
    background: `${style_exports.skyblue_color}`,
    borderRadius: "2rem",
    color: `${style_exports.btn_txtcolor}`,
    padding: "0.5rem 1rem",
    margin: "1rem",
    // width: "108px",
    // height: "36px",
    fontSize: "14px",
    textAlign: "center",
    fontWeight: "500",
    cursor: "pointer",
    "@media screen and (max-width: 599px)": {
      // width: "90px",
      margin: "0rem",
      fontSize: "12px",
    },
  },
  dragtext: {
    "@media screen and (max-width: 390px)": {
      fontSize: "12px",
    },
  },
  addOrRemoveButton: {
    borderRadius: "50% !important",
    minWidth: "35px !important",
    height: "35px",
    fontSize: "1.5rem !important",
  },
});

export const Portfolio_Styles = makeStyles({
  imageDragContainer: {
    aspectRatio: 16 / 16,
    borderRadius: "1rem",
    backgroundColor: "#F2F2F2",
    cursor: "pointer",
  },
  coverimageDragContainer: {
    aspectRatio: 16 / 8,
    borderRadius: "1rem",
    backgroundColor: "#F2F2F2",
  },
  multiMediaImageDragContainer: {
    aspectRatio: 16 / 9,
    borderRadius: "1rem",
    backgroundColor: "#F2F2F2",
    cursor: "pointer",
  },
  selectedImage: {
    width: "calc( 100% + -2px )",
    height: "100%",
    objectFit: "contain",
    border: "1px solid #F2F2F2",
    borderRadius: "1rem",
  },
  selectedImageContainer: {
    // height: "350px",
    // width: "400px",
    aspectRatio: 16 / 16,
    borderRadius: "1rem",
    overflow: "hidden",
  },
  selectedCoverImageContainer: {
    aspectRatio: 16 / 8,
    borderRadius: "1rem",
    overflow: "hidden",
  },
  selectedImageButton: {
    position: "absolute",
    // top: "50%",
    bottom: "0%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  tabBottonBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tabBottonBoxTittle: {
    fontSize: "1.8rem",
    fontWeight: 700,
    "@media screen and (max-width: 560px)": {
      fontSize: "1.3rem !important",
    },
  },
  tabBottonBoxAddButton: {
    "@media screen and (max-width: 560px)": {
      padding: "0.5rem 1rem",
    },
  },
  tablisted: {
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .MuiTab-root": {
      color: "#958c8c",
      padding: "0px",
      fontSize: "18px",
      fontWeight: "400",
      "@media screen and (max-width: 560px)": {
        fontSize: "14px",
      },
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#04BEF8",
      borderBottom: "2px solid #04BEF8",
      padding: "0px",
      minHeight: "35px",
      fontSize: "18px",
      fontWeight: "700",
      "@media screen and (max-width: 560px)": {
        fontSize: "14px",
      },
    },
    "& .MuiTabs-flexContainer": {
      gap: "5rem",
      "@media screen and (max-width: 560px)": {
        gap: "1rem",
      },
    },
  },
  dialogPaper: {
    "& .MuiDialog-paper": {
      borderRadius: "1.5rem",
    },
  },
  showPopText: {
    background: "#F2F2F2",
    padding: "16px 14px",
    borderRadius: "0.5rem",
  },
  borderbottomstyle: {
    borderBottom: "2px solid #d5d5d5",
    paddingBottom: "0.5rem",
  },
  cardborder: {
    display: "flex",
    borderRadius: "1rem",
    border: "1px solid #ababab",
    overflow: "hidden",
    width: "100%",
    aspectRatio: 16 / 9,
    padding: "0.5rem",
  },
  textellipsis: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  dialogcontent: {
    width: "100%",
    height: "350px",
    background: "#ffffff",
    borderRadius: "1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  closeiconstyle: {
    margin: "8px 8px 0px 0px",
    color: "#04BEF8",
    position: "absolute",
    right: 0,
    top: 0,
    cursor: "pointer",
    zIndex: 100,
  },
  cardgridheight: {
    width: "100%",
    height: "350px",
    // background: "#F2F2F2",
    borderRadius: "1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    "@media screen and (max-width: 599px)": {
      height: "auto",
      maxHeight: "350px",
    },
  },
  favoritestyle: {
    color: "black",
    backgroundColor: "white",
    width: "30px",
    height: "30px",
    padding: "0.3rem",
    borderRadius: "50%",
  },
});

export const Message_Styles = makeStyles({
  // messagesBoxContainer: {
  //   "@media screen and (max-width: 899px)": {
  //     width: "100%",
  //     overflowX: "scroll",
  //   }
  // },

  tabliststylechat: {
    background: "#e7e7e7",
    height: "100%",
    borderRadius: "0.5rem",
    cursor: "pointer",
  },
  headingstyle: {
    padding: "0rem 0.5rem 0.5rem 0.5rem",
    overflow: "hidden",
    maxWidth: "100%",
    textWrap: "nowrap",
    textOverflow: "ellipsis",
  },
  messagesBox: {
    "@media screen and (max-width: 899px)": {
      width: "100%",
      overflowX: "scroll",
    },
  },
  tabBottonBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media screen and (max-width: 899px)": {
      width: "900px !important",
    },
  },
  tablisted: {
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .MuiTab-root": {
      color: "#333333b8",
      fontSize: "1rem",
      padding: "0px",
      fontWeight: "600",
      background:
        window.location.pathname === "/admin/messages"
          ? "#fff"
          : "#F7F7F7",
      minWidth: "200px",
      borderRadius: "0.8rem 0.8rem 0rem 0rem",
      position: "relative",
    },
    "& .MuiTab-root.Mui-selected": {
      fontSize: "1rem",
      color: "#fff",
      padding: "0px",
      fontWeight: "600",
      background: "#04BEF8",
      minWidth: "200px",
      borderRadius: "0.8rem 0.8rem 0rem 0rem",
    },
    "& .MuiTabs-flexContainer": {
      gap: "1rem",
    },
  },
  chatBoxContainer: {
    border: `1px solid ${style_exports.chatBoxBorders}`,
    borderRadius: "0rem 0rem 1rem 1rem",
    overflow: "hidden",
    "@media screen and (max-width: 899px)": {
      width: "900px !important",
    },
  },
  chipStyles: {
    background: "#FF6E30 !important",
    color: "#ffff !important",
    minWidth: "24px",
    height: "24px !important",
    position: "absolute",
    right: "10%",
    "& span": {
      padding: "0px",
      paddingLeft: "0px",
      paddingRight: "0px",
      margin: "0.3rem",
    },
  },
  searchButtonStyle: {
    backgroundColor: "transparent",
    color: "#04BEF8",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  searchContainer: {
    padding: "1.5rem 3rem",
    borderBottom: `1px solid ${style_exports.chatBoxBorders}`,
  },
  chatBoxPersonsList: {
    borderRight: `1px solid ${style_exports.chatBoxBorders}`,
  },
  chatName: {
    position: "relative",
    width: "100%",
    "& .nameContainer": {
      fontWeight: "700",
      fontSize: "1rem",
      color: "#2E2E35",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "100%",
    },
    "& span": {
      fontSize: "0.7rem",
      position: "absolute",
      color: "#A19EAC",
      right: "0",
      top: "-0.8rem",
    },
  },
  chatMessage: {
    fontWeight: "400",
    fontSize: "0.7rem",
    color: "#464646",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "90%",
  },
  profileImage: {
    width: "54px !important",
    height: "54px !important",
  },
  userDetails: {
    padding: "1.2rem 1rem 1.2rem 0rem",
    borderBottom: `1px solid ${style_exports.chatBoxBorders}`,
  },
  chatBoxPersonsList_container: {
    paddingLeft: "1rem",
    cursor: "pointer",
    position: "relative",
    "& .MuiChip-root": {
      position: "absolute",
      color: "#A19EAC",
      right: "1rem",
      bottom: "0.2rem",
      backgroundColor: "#FF6E30",
      color: "#fff",
      minWidth: "16px",
      height: "16px !important",
      "& span": {
        fontSize: "11px",
        padding: "0px",
        paddingLeft: "0px",
        paddingRight: "0px",
      },
    },
  },
  chatBoxPersonsListSize: {
    height: "calc( 50vh + 58px )", //"735.93px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  messageBox: {
    height: "50vh",
    overflowY: "scroll",
    padding: "1rem",
    // borderBottom: `1px solid ${style_exports.chatBoxBorders}`,
    "&::-webkit-scrollbar": {
      display: "none",
    },
    // background: `url(${chatBg2})`,
  },
  inputBox: {
    height: "81.77px",
    padding: "0rem 1rem",
    borderTop: `1px solid ${style_exports.chatBoxBorders}`,
  },
  message: {
    fontSize: "0.9rem",
    maxWidth: "100%",
    wordBreak: "break-word",
  },
  onetoOneChat: {
    backgroundColor: style_exports.chatMessageGrayBackground,
    padding: "0.2rem 0.6rem",
    borderRadius: "1rem",
    maxWidth: "100%",
    wordBreak: "break-word",
  },
  filterMedia_tablisted: {
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .MuiTab-root": {
      color: "#958c8c",
      padding: "0px",
      fontSize: "16px",
      fontWeight: "700",
    },
    "& .MuiTab-root.Mui-selected": {
      color: "#04BEF8",
      borderBottom: "2px solid #04BEF8",
      padding: "0px",
      minHeight: "35px",
      fontSize: "16px",
      fontWeight: "700",
    },
    "& .MuiTabs-flexContainer": {
      gap: "1rem",
      position: "relative",
      "& .closeIcon": {
        position: "absolute",
        right: "0",
        top: "0",
        cursor: "pointer",
      },
    },
  },
});

export const pricingplanstyles = makeStyles({
  divcolor: {
    padding: "0rem 1rem",
    borderRadius: "1.5rem",
    height: "100%",
  },
  planstextstyle: {
    textTransform: "none",
    borderRadius: "0.5rem",
    color: "#333333",
    fontWeight: "700",
    paddingTop: "0.8rem",
    paddingBottom: "0.8rem",
  },
});

export const reviewbarsstyles = makeStyles({
  fivestarcolor: {
    height: "13px",
    borderRadius: "1rem",
    "&.MuiLinearProgress-colorPrimary": {
      backgroundColor: style_exports.grey_color, // Set the background color for unprogressed values
    },
    "& .MuiLinearProgress-bar": {
      backgroundColor: style_exports.skyblue_color, // Set the progress color
      borderRadius: "1rem",
    },
  },
  fourstarcolor: {
    height: "13px",
    borderRadius: "1rem",
    "&.MuiLinearProgress-colorPrimary": {
      backgroundColor: style_exports.grey_color, // Set the background color for unprogressed values
    },
    "& .MuiLinearProgress-bar": {
      backgroundColor: "#7CE886", // Set the progress color
      borderRadius: "1rem",
    },
  },
  threestarcolor: {
    height: "13px",
    borderRadius: "1rem",
    "&.MuiLinearProgress-colorPrimary": {
      backgroundColor: style_exports.grey_color, // Set the background color for unprogressed values
    },
    "& .MuiLinearProgress-bar": {
      backgroundColor: "#C5D467",
      borderRadius: "1rem", // Set the progress color
    },
  },
  twostarcolor: {
    height: "13px",
    borderRadius: "1rem",
    "&.MuiLinearProgress-colorPrimary": {
      backgroundColor: style_exports.grey_color,
    },
    "& .MuiLinearProgress-bar": {
      backgroundColor: "#F7941D",
      borderRadius: "1rem",
    },
  },
  onestarcolor: {
    height: "13px",
    borderRadius: "1rem",
    "&.MuiLinearProgress-colorPrimary": {
      backgroundColor: style_exports.grey_color,
    },
    "& .MuiLinearProgress-bar": {
      backgroundColor: "#c37aff",
      borderRadius: "1rem",
    },
  },
});
